
import React from "react"
import HorizontalLayout from "components/HorizontalLayout"
import DrRafia from 'assets/images/custom/dr_rafia.jpeg'
import Abdullah from 'assets/images/custom/abdullah.jpeg'
import Amna from 'assets/images/custom/amna.jpeg'
import Afifa from 'assets/images/custom/afifa.jpeg'
import Usama from 'assets/images/custom/usama.jpeg'

const TeamPage = ()=>{

    document.title = "Team - NUST Air Quality Monitoring"

    return (
        <HorizontalLayout>
            <div className="page-content">
                <h1 className="text-primary text-center mb-5">Team</h1>
                <div className="d-flex flex-wrap mb-5 w-100 justify-content-center align-items-center container gap-4">
                    <div className="shadow-sm p-3 rounded-2 d-flex flex-column align-items-center justify-content-center">
                        <div className="rounded-circle shadow-sm" style={{
                            backgroundImage:`url("${DrRafia}")`,
                            width:"220px",
                            height:"220px",
                            backgroundPosition:"top",
                            backgroundSize:"cover",
                            }}>

                        </div>
                        <h3 className="text-primary text-center mt-2">Prof.Dr. Rafia Mumtaz</h3>
                        <p className="text-secondary text-center fw-bold">Principle investigator</p>
                    </div>
                    <div className="shadow-sm p-3 rounded-2 d-flex flex-column align-items-center justify-content-center">
                        <div className="rounded-circle shadow-sm" style={{
                            backgroundImage:`url("${Abdullah}")`,
                            width:"220px",
                            height:"220px",
                            backgroundPosition:"top",
                            backgroundSize:"cover",
                            }}>

                        </div>
                        <h3 className="text-primary text-center mt-2">Abdullah</h3>
                        <p className="text-secondary text-center fw-bold">Team Lead</p>
                    </div>
                    <div className="shadow-sm p-3 rounded-2 d-flex flex-column align-items-center justify-content-center">
                        <div className="rounded-circle shadow-sm" style={{
                            backgroundImage:`url("${Amna}")`,
                            width:"220px",
                            height:"220px",
                            backgroundPosition:"top",
                            backgroundSize:"280px",
                            backgroundColor:"white",
                            }}>

                        </div>
                        <h3 className="text-primary text-center mt-2">Amna Hassan</h3>
                        <p className="text-secondary text-center fw-bold">AI Expert</p>
                    </div>
                    <div className="shadow-sm p-3 rounded-2 d-flex flex-column align-items-center justify-content-center">
                        <div className="rounded-circle shadow-sm" style={{
                                backgroundImage:`url("${Afifa}")`,
                                width:"220px",
                                height:"220px",
                                backgroundPosition:"top",
                                backgroundSize:"220px 300px",
                                backgroundColor:"white",
                                }}>

                        </div>
                        <h3 className="text-primary text-center mt-2">Afifa Maryam</h3>
                        <p className="text-secondary text-center fw-bold">Media & Content Writer</p>
                    </div>
                    <div className="shadow-sm p-3 rounded-2 d-flex flex-column align-items-center justify-content-center">
                        <div className="rounded-circle shadow-sm" style={{
                            backgroundImage:`url("${Usama}")`,
                            width:"220px",
                            height:"220px",
                            backgroundPosition:"top",
                            backgroundSize:"cover",
                            }}>

                        </div>
                        <h3 className="text-primary text-center mt-2">Muhammad Usama</h3>
                        <p className="text-secondary text-center fw-bold">Web Developer</p>
                    </div>
                </div>
                <h1 className="text-primary text-center mb-3">Contact Us</h1>
                <h4 className="text-center mb-5">Email us at: <a href="mailto:iotlab@seecs.edu.pk">iotlab@seecs.edu.pk</a></h4>
                

            </div>
        </HorizontalLayout>
    )
}

export default TeamPage